
import { 
    IonContent, 
    IonPage, 
    IonGrid, 
    IonRow, 
    IonCol, 
    IonButton, 

    onIonViewDidEnter,
  
  } from '@ionic/vue';
import { defineComponent, ref } from '@vue/runtime-core';
import { useRoute, useRouter } from 'vue-router';
import t from '@/translation';

export default  defineComponent({
  name: 'Login',
  components: { 
    IonContent, 
    IonPage, 
    IonGrid, 
    IonRow, 
    IonCol, 
    //IonText, 
    IonButton, 
    //IonInput
    
  },
  setup(){
  
    // Create a form context with the validation schema
    const router = useRouter();
    const route = useRoute();

    const message = ref("");

    function onRetryAccess(){

      // redirecting to login will cause the app to poll for userData and 
      // return here if we are still in maintenance mode.
      router.push('/login');
    }
    
    onIonViewDidEnter(async () => {
      message.value = typeof route.query.message == 'string' ? route.query.message : t("We're taking some time out to work on our own wedding.  Please check back in a few minutes.");
    });

    return {
      message,
      onRetryAccess,
    };
  },
});
